import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import ToggiGoatfeature from '../../images/goats/toggenburgskaya_poroda_koz/toggi-feature.jpg'
import ToggiGoatGallery1 from '../../images/goats/toggenburgskaya_poroda_koz/plemennaya-toggenburgskaia-koza.jpg'
import ToggiGoatGallery2 from '../../images/goats/toggenburgskaya_poroda_koz/plemennaya-toggenburgskaia-koza-1.jpg'
import ToggiGoatGallery3 from '../../images/goats/toggenburgskaya_poroda_koz/toggi-feature-3.jpg'
import ToggiGoatGallery4 from '../../images/goats/toggenburgskaya_poroda_koz/toggi-goat-na-vistavke-pobeditel.jpg'


const ToggyPoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание Тоггенбургской породы коз', url:'#', status:true}]

    const indicators = [
      {name: 'Надой за 305 дней(л)', progress: 1045, benchmark:1200},
      {name: 'Надой в день в среднем(л)', progress: 3.43, benchmark:4},
      {name: 'Жирность молока(%)', progress: 3.0, benchmark:6},
      {name: 'Белки(%)', progress: 2.7, benchmark:5},
      {name: 'Жир + белки(%)', progress: 5.7, benchmark:10},
      {name: 'Лактация дней', progress: 315, benchmark: 365},
      {name: 'Вес коз', progress: 55, benchmark:100},
      {name: 'Приплод', progress: 260, benchmark:300},
  ]

    const galleryImage = [
      {
        src: ToggiGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Тоггенбургская порода коз',
        title: 'Тоггенбургская порода коз'
      },
      {
        src: ToggiGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Тоггенбургская порода коз',
        title: 'Тоггенбургская порода коз'
      },
      {
        src: ToggiGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Тоггенбургская порода коз',
        title: 'Тоггенбургская порода коз'
      },
      {
        src: ToggiGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Тоггенбургская порода коз',
        title: 'Тоггенбургская порода коз'
      }
    ];
  return (
    <div>
        <SEO title="Тоггенбургская Порода Коз: описание, фото, характеристики"
            description="Тоггенбургская Порода Коз">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Тоггенбургская Порода Коз'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Тоггенбургская Порода Коз</h1>
                    <p className="text-justify">
                    Самым главным показателем в козоводстве является получение максимального 
                    количества качественного молока. Тоггенбургская порода коз идеально 
                    подходит по этим показателям.
                    </p>
                    <p className="text-justify">
                      Помимо своего древнего происхождения, тогги считаются одними из 
                      высокопродуктивных молочных пород.  
                    </p>
                <Row>
                  <Col md="12">
                    <h4>Оглавление:</h4>
                  </Col>
                  <ul className="pl-5">
                    <li><a href="#origin">Происхождение породы</a></li>
                    <li><a href="#description">Описание породы</a></li>
                    <li><a href="#productivity">Продуктивность Альпийских коз</a></li>
                  </ul>
                </Row>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={ToggiGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Фото до и после внутренней покраски дома из сруба" 
                                                            title="Фото до и после внутренней покраски дома из сруба" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="12" className="pl-md-5">
               <h2 id="origin">Происхождение породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Тоггенбургская порода коз считается одной из древнейших молочных пород, она 
                распространена во всем мире, по крайней мере, в пятидесяти странах на всех 
                континентах. Роддом “Тогги” из холодной Швейцарии долины Тоггенбург в 
                кантоне Санкт-Галлена, откуда и получила свое название тоггенбургской 
                породы коз. Кстати именно потому, что “Тогги” на протяжении долго времени 
                выводили в Швейцарии она прекрасно адаптируется к холодам и морозам.
              </p>
              <p className="text-justify" className="text-justify">
                Впервые Тоггенбургская порода была зарегистрирована в Швейцарии  в 1890 году.  
                Тоггенбургцы были первой козьей породой из Швейцарии, которая была завезена в  
                Соединенное Королевств. Прибыв в Англию в 1884 году Тоггенбургская порода  
                претерпела программу развития английскими селекционерами. 
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
                В Результате была выведена новая разновидность “Тогги” под названием  
                Британская Тоггенбургская. Впоследствии Тоггенбургской  породой коз  
                заинтересовались и другие страны. Из за селекции в других странах, “Тогги” 
                претерпели некоторые изменения и появилась новые разновидности данной породы. 
                Самые известные: Британская тоггенбургская (Англия), Благородная 
                Тоггенбургская (Швейцария), Тюрингская порода (Германия).
              </p>
              <p className="text-justify" className="text-justify">
              В Российскую Империю “Тогги” ввезли  незадолго до Первой мировой войны, впоследствии  
              информация о судьбе животных к сожалению была утеряна.
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото коз Тоггенбургской породы</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="description">Описание породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">Отличительным признаком “Тогги” является  поистине 
                удивительный окрас. Тело тоггенбургцев  может быть разных оттенков бурого, 
                а вот вдоль мордочки тянутся  две параллельные белые полосы, при этом 
                ноги и ушки тоже белые. Шерсть у них мягкая и шелковистая, она может быть 
                как длинной, так и короткой. В основном длинная шерсть присутствует на 
                спинке животного.
              </p>
              <p className="text-justify">Тогги значительно меньше своих сородичей других пород.</p>
              <p className="text-justify">Вес:</p>
              <ul>
                <li>Самец :75 кг </li>
                <li>Самка:55 кг</li>
              </ul>
              <p className="text-justify">Высота:</p>
              <ul>
                <li>Самец :85 см </li>
                <li>Самец:75 см </li>
              </ul>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <p className="text-justify">Телосложение у данной породы очень гармоничное.  
                Ноги длинные и сильные, правильно поставленные. Спина прямая, ребра выпуклые. 
                Ушки небольшие, стоячие и довольно узкие, направленные вперед. Шея длинная и эстетичная.
              </p>
              <p className="text-justify">
                У тоггенургской породы коз полностью отсутствуют рожки, то есть являются комолыми. Это относится как к козочкам, так и к козликам
              </p>
              <p className="text-justify">
                Тоггенбургская порода коз отлично приспособлена к холоду, что делает ее идеальной для горных и северных регионов России.
              </p>
              <p className="text-justify">Голова у альпиек в соотношении с туловищем маленькая, 
                уши прямые, самцы имеют бороду и рога. Холка с выраженным удлинением шерсти, конечности длинные с мощными копытами, длинный хвост.
              </p>
              <p className="text-justify">Породу любят не только за ее разнообразный,  
                пестрый окрас, но и за качество и показатели продуктивности молока.
              </p>
             </Col>
           </Row>
         </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="productivity">Характеристики продуктивности Тоггенбургской породы коз</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Удойность</h3>
              <p className="text-justify">Как говорилось уже выше самым главным показателем 
                тоггенбургской породы коз является молочность. Лактационный период 
                длится в целом около 9 месяцев (280 дней).  Стандарт породы требует 
                минимального выхода молока 740 кг на лактацию с минимальным содержанием 
                жира 3,56% и минимальным содержанием белка 2,90%. Молоко тогги 
                отлично подходит для производства сыров и йогуртов.
              </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Плодовитость</h3>
              <p className="text-justify">Тогги отличаются хорошей  плодовитостью, в одном помете может быть от одного до трех козлят</p>
             </Col>
           </Row>
         </Container>
         <FormQuickOrder className="mt-3" />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default ToggyPoroda;